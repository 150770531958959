

export type Listener = (...args: any[]) => void;


export class ListenerStore<T extends Listener> {
    private readonly listeners: T[];

    constructor() {
        this.listeners = [];
    }

    length(): number {
        return this.listeners.length;
    }

    add(listener: T): () => void {
        for (const registeredListener of this.listeners) {
            if (registeredListener === listener)
                throw new Error("Listener already registered!");
        }

        this.listeners.push(listener);
        return () => {
            this.remove(listener);
        };
    }

    remove(listener: T) {
        for (let index = 0; index < this.listeners.length; ++index) {
            if (listener === this.listeners[index]) {
                this.listeners.splice(index, 1);
                break;
            }
        }
    }

    invoke(...args: Parameters<T>) {
        for (const listener of this.listeners) {
            listener(...args);
        }
    }
}
